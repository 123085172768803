<template>
  <div class="disclosure">
    <h1>Rocky Mountain Power Plan</h1>
    <h2>Promotion Terms and Conditions</h2>
    <p class="primary-color"><strong>Effective as of May 2024</strong></p>
    <p>
      Electrify America, LLC (“Electrify America”) and Rocky Mountain Power (“Pacificorp”) (“RMP”) are pleased to
      provide the Rocky Mountain Power Plan (“Plan”) to customers of Rocky Mountain Power. This Promotion provides 7
      years of discounted charging at RMP owned stations. The Promotion starts from the date of customer enrollment in
      the Electrify America App. Pricing is subject to change according to Schedule 60, which is approved by the Utah
      Public Service Commission.
    </p>
    <p>
      By clicking “Agree” below, you agree to participate in this Plan, subject to your compliance with all Electrify
      America terms applicable to the Plan (including the terms contained in this disclosure and the app’s Terms of Use,
      which together govern your use of the Plan).
    </p>
    <p>
      Participation requires an Electrify America account, the Electrify America mobile app, enrollment in the Rocky
      Mountain Power Plan and acceptance of Electrify America’s
      <router-link class="medium" :to="{ name: 'terms-en' }">Terms of Use</router-link>, and
      <router-link class="medium" :to="{ name: 'privacy-en' }">Privacy Policy</router-link>. Your mobile carrier’s
      normal messaging, data, and other rates and fees will apply to your use of the Electrify America app. You may also
      be required to use a credit or debit card to load dollars to an account for idle fees or other charging sessions
      not covered by your plan and you agree to an automatic reload feature for the payment method used by the app. See
      the Electrify America Terms of Use available at https://www.electrifyamerica.com/terms for further details.
    </p>
    <p>
      Immediately upon your registration and acceptance of these terms, you will be prompted to use an enrollment code
      for the program that provides a discount of $0.27/kWh and an off-peak credit of $0.05/kWh, compliments of Rocky
      Mountain Power. The period of the off-peak credit will be 10:00am- 3:00pm MST and 8:00pm-8:00am MST Monday-Friday
      except holidays October through May inclusive. June through September inclusive off-peak charging credit will
      include all times outside of 3:00pm- 8:00pm MST. You may also redeem this credit on Rocky Mountain Power’s network
      of electric vehicle charging stations if you are a Non-RMP Customer.
    </p>
    <p>
      After enrolling, you can redeem your charging discount and enter your account number during your initiation of a
      charging session at any Rocky Mountain Power charging station, exclusively by using the Electrify America app
      “Swipe to start” or NFC Pass feature with the appropriate plan selected.
      <strong>This enrollment code is limited to one redemption per household per account number</strong>. This
      discounted charging may not be initiated using the interface screen or buttons on the charger. Plug&Charge
      functionality will not be available for use with this plan.
    </p>
    <p>
      With the exclusion of pre-funding per our
      <router-link class="medium" :to="{ name: 'terms-en' }">Terms of Use</router-link>, there is no additional fee or
      purchase required for your access to this promotion. There is a $1 session fee that will be applied at the
      conclusion of the session unless you have an OEM premium bundle plan. After the off-peak credit timeline has been
      exhausted, you are responsible for all charging fees (including any applicable tax and idle fees) under the
      requisite plan.
    </p>
    <p class="primary-color">
      <strong>PLEASE NOTE THE FOLLOWING IMPORTANT LIMITATIONS ON YOUR USE OF THIS PROMOTION:</strong>
    </p>
    <p>
      You may <strong>only</strong> use the Rocky Mountain Power Plan for charging with the account number you submitted
      when signing up for this promotion on your Electrify America app. This benefit is for Rocky Mountain Power account
      holders’ only.
    </p>
    <p>
      The discounted charging <strong>does include</strong> idle fees and their associated applicable taxes assessed at
      the charging station. You will be responsible for paying such fees through your account if you incur them during a
      charging session.
    </p>
    <p>
      You must follow all product, vehicle, safety, and technical documentation included with the vehicle when charging
      at a Rocky Mountain Power charging station.
    </p>
    <p>
      Once the charging discount expires, you will not be able to use this plan. At that time, your account will
      automatically convert to a basic Electrify America “Pass or Pass +” account. You may, but are not obligated to,
      sign up for a different type of subscription via the Electrify America mobile app. Please refer to the information
      on the <strong>“Plan Details”</strong> section of the app to keep track of the amount of time remaining in this
      promotion.
    </p>
    <p>
      This credit is not returnable or redeemable for any cash value, and may not be sold, loaned, or otherwise
      distributed to any third party for any reason. You may not transfer any portion of the credit to any other
      Electrify America account.
    </p>
    <p>
      You agree to comply with all applicable laws and regulations when using Electrify America services and when
      charging your vehicle at Rocky Mountain Power charging stations.
    </p>
    <p>
      Rocky Mountain Power reserves the right to withhold, revoke, reduce, terminate, or suspend your access to all or
      any portion of this promotion, without notice, if Rocky Mountain Power determines or suspects, in its sole
      discretion, that you: (a) are in violation of the Electrify America Terms of Use, these Promotion Terms and
      Conditions, or any other contract between you and Rocky Mountain Power; (b) have engaged in charging sessions that
      are excessive, fraudulent, or otherwise indicate an unauthorized or unintended use of this complimentary
      promotion; or (c) have engaged in any illegal, fraudulent, tortious, injurious, harmful, or abusive conduct in
      your use of the Electrify America services, the Electrify America mobile app, or a Rocky Mountain Power charging
      station.
    </p>
    <p>
      Rocky Mountain Power reserves the right to end this promotion at any time before its automatic termination date
      for any reason, in its sole discretion, provided that Rocky Mountain Power will send an email to the address
      associated with your account in the event of any change to the scheduled end date of this promotion. Following the
      termination or expiration of this program for any reason, your access to this promotion will immediately cease,
      and you may not use or redeem any remaining portion of the discounted charging.
    </p>
  </div>
</template>

<script>
export default {
  name: 'rocky-mountain-power-plan-disclosure',
  metaInfo: {
    title: 'Rocky Mountain Power Plan Disclosure | Electrify America',
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/rocky-mountain-power-plan-disclosure/' }],
  },
};
</script>
